import ApiRequest from '@/core/api/ApiRequest'
import AccessModel from '@/core/models/AccessModel'
import LoggedUserModel from '@/core/models/LoggedUserModel'
import UserModel from '@/core/models/User'
import Chat from '@/core/models/Chat'
import ChatMessage from '@/core/models/ChatMessage'


export default class RootState {
  public isLoading: boolean = false

  public isUserDialogShown: boolean = false

  public fatalErrorMessage: string | null = null

  public accessModel: AccessModel | null

  public apiRequest: ApiRequest | null

  public loggedUser: LoggedUserModel | null

  public messagesList: ChatMessage[]

  public chatsList: Chat[]

  public chatsListUpdatedAt: string | null

  public hasUnreadMessages: boolean

  public activeChatId: string

  constructor () {
    this.accessModel = null
    this.apiRequest = null
    this.loggedUser = null
    this.isUserDialogShown = false
    this.chatsList = []
    this.messagesList = []
    this.chatsListUpdatedAt = null
    this.fatalErrorMessage = null
    this.hasUnreadMessages = false
    this.activeChatId = ''
  }
}
