import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Mutation } from 'vuex-class'

import { SET_MESSAGES } from '@/store/root/mutations'

import Header from './Header/Header.vue'
import MessageDisplay from './MessageDisplay/MessageDisplay.vue'
import MessageManager from './MessageManager/MessageManager.vue'
import UnreadMessages from './UnreadMessages/UnreadMessages.vue'
import ChatMessage from '@/core/models/ChatMessage'


@Component({
  components: { Header, MessageDisplay, MessageManager, UnreadMessages },
})
export default class Chat extends Vue {
  @Prop()
  public title: string;

  @Prop()
  public image: string;

  @Prop()
  public readOnly: boolean;

  @Prop()
  public unreadChatsCount: boolean;

  @Prop({ required: true })
  public messages: Array<any>;

  @Prop({ default: true })
  public sendImages: boolean | null;

  @Prop({
    default: {
      my: {},
      others: {}
    }
  })
  public linkOptions: any;

  @Prop({ default: 'image/*' })
  public acceptImageTypes: string | null;

  @Mutation(SET_MESSAGES)
  public setMessages: (messages: any[]) => void

  // TODO: remove
  @Watch('messages')
  public updateMessages () {
    this.setMessages(this.messages)
  }


  public onType (e: any) {
    this.$emit('onType', e)
  }

  public onMessageSubmit (message: ChatMessage) {
    this.$emit('onMessageSubmit', message)
  }

  public onImageSelected (message: ChatMessage, imageFile: File) {
    this.$emit('onImageSelected', message, imageFile)
  }

  public onImageClicked (message: any) {
    this.$emit('onImageClicked', message)
  }


  public created () {
    this.setMessages(this.messages)
  }
}
