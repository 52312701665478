import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

import ChatMessage from '@/core/models/ChatMessage'
import ChatModel from '@/core/models/Chat'
import LoggedUserModel from '@/core/models/LoggedUserModel'

import {
  CHANGE_ACTiVE_CHAT_ID,
  REFRESH_CHAT_MESSAGES,
  REFRESH_CHATS_LIST,
  RESET_CHAT_MESSAGES,
  SUBMIT_MESSAGE,
  SUBMIT_PHOTO,
} from '@/store/root/actions'

import { GET_CHATS_LIST, GET_LAST_NOT_MY_MESSAGE, GET_LOGGED_USER, GET_MESSAGES } from '@/store/root/getters'

import Chat from '@/components/shared/Chat/Chat.vue'


@Component({
  components: { Chat }
})
export default class ChatPage extends Vue {
  /** Id of current chat. */
  @Prop()
  public id: string;

  @Action(RESET_CHAT_MESSAGES)
  public resetChatMessages: (chatId: string) => Promise<void>

  @Action(REFRESH_CHAT_MESSAGES)
  public refreshChatMessages: (chatId: string) => Promise<void>

  @Action(REFRESH_CHATS_LIST)
  public refreshChatsList: () => void

  @Action(CHANGE_ACTiVE_CHAT_ID)
  public changeActiveChatId: (payload: string) => void

  @Getter(GET_MESSAGES)
  public getMessages: ChatMessage[]

  @Getter(GET_LOGGED_USER)
  public loggedUser: LoggedUserModel | null

  @Getter(GET_LAST_NOT_MY_MESSAGE)
  public lastNotMyMessage: ChatMessage | null

  @Getter(GET_CHATS_LIST)
  public chatsList: ChatModel[]

  @Action(SUBMIT_MESSAGE)
  public submitMessage: (message: ChatMessage) => void

  @Action(SUBMIT_PHOTO)
  public submitPhoto: (photoValue: File) => Promise<string>

  public STATUS_SENDED = 'sent';

  public STATUS_VIEWED = 'viewed';

  public linkOptions: any = {
    my: {
      className: 'myLinkClass',
      events: {
        click: function (e: any) {
          console.log('Link clicked!') // TODO: remove!!!
        },
        mouseover: function (e: any) {
          // console.log('Link hovered!') // TODO: remove!!!
        }
      },
      format: function (value: any, type: any) {
        if (type === 'url' && value.length > 50) {
          value = value.slice(0, 50) + '…'
        }
        return value
      }
    },
    others: {
      className: 'othersLinkClass',
      events: {
        click: function (e: any) {
          console.log('Link clicked!') // TODO: remove!!!
        },
        mouseover: function (e: any) {
          // console.log('Link hovered!') // TODO: remove!!!
        }
      },
      format: function (value: any, type: any) {
        if (type === 'url' && value.length > 50) {
          value = value.slice(0, 50) + '…'
        }
        return value
      }
    }
  }

  public onType (event: any): void {
    // here you can set any behavior
  }

  public onMessageSubmit (message: ChatMessage): void {
    this.submitMessage(message)
  }

  public async onImageSelected (message: ChatMessage, imageFile: File): Promise<void> {
    const url = await this.submitPhoto(imageFile)

    const attachment = message.attachments![0]
    attachment.image = url
    attachment.preview = url

    this.submitMessage(message)
  }

  public onImageClicked (message: any): void {
    /**
     * This is the callback function that is going to be executed when some image is clicked.
     * You can add your code here to do whatever you need with the image clicked. A common situation is to display the image clicked in full screen.
     */
    // console.log('Image clicked')
  }

  public get chat () {
    return this.chatsList?.find((chat) => chat.id === this.id)
  }

  public get unreadChatsCount () {
    return this.chatsList
      .filter(chat => chat.id !== this.id)
      .reduce((count, chat) => count + (chat.unreadCount ? 1 : 0), 0)
  }


  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    this.changeActiveChatId(this.id)
    this.resetChatMessages(this.id)
    await this.refreshChatMessages(this.id)
    await this.refreshChatsList()
  }
}
