import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { GET_CHATS_LIST } from '@/store/root/getters'

import ChatItem from '@/components/shared/Chats/ChatItem/ChatItem.vue'
import Chat from '@/core/models/Chat'

@Component({
  components: { ChatItem },
})

export default class ChatsPage extends Vue {
  @Getter(GET_CHATS_LIST)
  public chatsList: Chat[];

  public async mounted () {
  }

  public async created () {
  }
}
