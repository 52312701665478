import Chat from '@/core/models/Chat'
import router from '@/router'
import { Prop, Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { GET_LOGGED_USER } from '@/store/root/getters'
import LoggedUserModel from '@/core/models/LoggedUserModel'

@Component({})

export default class ChatItem extends Vue {
  @Prop({ required: true })
  public chat: Chat;

  @Getter(GET_LOGGED_USER)
  public loggedUser: LoggedUserModel | null

  public get description () {
    const { lastMessage, description } = this.chat
    const { text, attachments } = lastMessage || {}

    if (attachments?.length) {
      return 'Отправлено фото'
    }

    if (!text) {
      return description
    }

    if (lastMessage?.sender?.id === this.loggedUser?.id) {
      return `Вы: ${text}`
    }
    return text
  }

  public get unreadCount () {
    return this.chat?.unreadCount || 0
  }

  public onClicked (): void {
    router.push(`/chat/${this.chat.id}`)
  }

  public async mounted () {
  }
}
