import { ActionContext, ActionTree } from 'vuex'
import moment from 'moment'

import ApiRequest from '@/core/api/ApiRequest'
import AccessModel from '@/core/models/AccessModel'
import ResponseModel from '@/core/models/ResponseModel'
import UserModel from '@/core/models/User'
import Chat from '@/core/models/Chat'
import ChatMessage from '@/core/models/ChatMessage'
import LoggedUserModel from '@/core/models/LoggedUserModel'

import RootState from '@/store/root/state'
import * as mutations from '@/store/root/mutations'


export const LOG_IN_USER: string = 'logInUser'

export const REFRESH_CHATS_LIST: string = 'refreshChatsList'

export const REFRESH_CHAT_MESSAGES: string = 'refreshChatMessages'

export const RESET_CHAT_MESSAGES: string = 'resetChatMessages'

export const REFRESH_LOGGED_USER: string = 'refreshLoggedUser'

export const SUBMIT_MESSAGE: string = 'submitMessage'

export const SUBMIT_PHOTO: string = 'submitPhoto'

export const CHANGE_ACTiVE_CHAT_ID: string = 'changeActiveChatId'

export function logInUser (
  store: ActionContext<RootState, any>,
  payload: { login: string, password: string },
) {
  const { login, password } = payload
  return store.getters.getApiRequest.send(
    'POST',
    'auth',
    JSON.stringify({ login, password })
  ).then((response: ResponseModel) => {
    const { access_token, refresh_token } = response.data
    const accessModel = new AccessModel(access_token, refresh_token)
    store.commit(mutations.SET_ACCESS_MODEL, accessModel)

    return store.getters.getApiRequest.send(
      'GET',
      'auth',
    )
  }).then((response: ResponseModel) => {
    const user = response.data
    const accessModel = store.getters.getAccessModel
    accessModel.issuedAt = user.iat
    accessModel.expiredAt = user.exp

    accessModel.user = new UserModel()
    accessModel.user.id = user.id
    accessModel.user.username = user.username
    accessModel.user.fullname = user.fullname
    accessModel.user.email = user.email
    accessModel.user.role = user.role
    accessModel.user.created_at = user.created_at

    store.commit(mutations.SET_ACCESS_MODEL, accessModel)
  })
}

export function refreshChatsList (
  store: ActionContext<RootState, any>,
) {
  return store.getters.getApiRequest.send(
    'GET',
    'chats?' + Date.now()
  ).then((response: ResponseModel) => {
    const chats = response.data
    store.commit(mutations.SET_CHATS_LIST_UPDATED_AT, moment().toISOString())

    const oldChats = store.state.chatsList
    const messagesOfChat: any = {}
    for (const chat of oldChats) {
      messagesOfChat[chat.id] = chat.messages
    }

    const newChats = []
    for (const chat of chats) {
      newChats.push({
        id: chat.id,
        name: chat.name,
        image: chat.image,
        lastMessage: chat.last_message,
        description: chat.description,
        position: chat.position,
        unreadCount: chat.unread_count,
        readOnly: chat.read_only,
        messages: messagesOfChat[chat.id] ? messagesOfChat[chat.id] : [],
      })
    }
    store.commit(mutations.SET_CHATS_LIST, newChats)
  })
}

export function refreshChatMessages (
  store: ActionContext<RootState, any>,
  chatId: string
) {
  if (!store.state.loggedUser) {
    return
  }

  if (chatId !== store.state.activeChatId) {
    console.log(`Wrong chatId (${chatId} but active ${store.state.activeChatId}), stage 0`) // TODO: remove
    return
  }

  const lastNotMyMessage = store.state.messagesList.findLast(
    (message: any) => message.sender.id !== store.state.loggedUser!.id
  )
  const from = lastNotMyMessage ? lastNotMyMessage.createdAt : ''

  return store.getters.getApiRequest.send(
    'GET',
    'chats/' + encodeURI(chatId) + '?from=' + encodeURI(from)
  ).then((response: ResponseModel) => {
    if (chatId !== store.state.activeChatId) {
      console.log(`Wrong chatId (${chatId} but active ${store.state.activeChatId})`) // TODO: remove
      return
    }

    const chatMessages: ChatMessage[] = store.state.messagesList
    const existedIds = chatMessages.map((message: any) => message.id)

    const messages = response.data && response.data.messages ? response.data.messages : []

    const newMessages: ChatMessage[] = []
    for (const message of messages) {
      if (existedIds.includes(message.id)) {
        continue
      }

      const sender = new UserModel()
      sender.id = message.sender.id
      sender.name = message.sender.name
      sender.image = message.sender.image
      sender.isMe = message.sender.id === store.state.loggedUser?.id

      newMessages.push({
        id: message.id,
        title: message.title,
        text: message.text,
        attachments: message.attachments,
        status: message.status,
        createdAt: message.created_at,
        sender,
      })
    }

    if (newMessages.length) {
      store.commit(mutations.ADD_MESSAGES, newMessages)
      store.commit(mutations.ADD_CHAT_MESSAGES, { chatId, messages: store.state.messagesList })
      store.commit(mutations.SET_UNREAD_MESSAGES, from ? newMessages.length : 0)
    }
  })
}

export function resetChatMessages (
  store: ActionContext<RootState, any>,
  chatId: string,
) {
  const chatIndex = store.state.chatsList.findIndex((chat) => chat.id === chatId)
  store.commit(mutations.SET_MESSAGES, store.state.chatsList[chatIndex] ? store.state.chatsList[chatIndex].messages : [])
}

export function refreshLoggedUser (
  store: ActionContext<RootState, any>,
) {
  return store.getters.getApiRequest.send(
    'GET',
    'auth/user',
  ).then((response: ResponseModel) => {
    store.commit(mutations.SET_LOGGED_USER, new LoggedUserModel(response.data))
  })
}


export function submitMessage (
  store: ActionContext<RootState, any>,
  message: ChatMessage,
) {
  return store.getters.getApiRequest.send(
    'POST',
    'chats/support',
    JSON.stringify({
      text: message.text,
      attachments: message.attachments,
    })
  ).then((response: ResponseModel) => {
    message.id = response.data.id
    message.status = 'sent'
    store.commit(mutations.ADD_CHAT_MESSAGES, {
      chatId: store.state.activeChatId,
      messages: store.state.messagesList,
    })
  })
}

export async function submitPhoto (
  store: ActionContext<RootState, any>,
  image: File,
) {
  const formData = new FormData()
  formData.append('file', image)

  return store.getters.getApiRequest.send(
    'POST',
    'chats/support/photos',
    formData
  ).then((response: ResponseModel) => {
    return response.data.url
  })
}

export function changeActiveChatId (
  store: ActionContext<RootState, any>,
  chatId: string,
) {
  store.commit(mutations.SET_ACITVE_CHAT_ID, chatId)
}

export default {
  changeActiveChatId,
  logInUser,
  refreshChatsList,
  refreshChatMessages,
  resetChatMessages,
  refreshLoggedUser,
  submitMessage,
  submitPhoto,
} as ActionTree<RootState, any>
