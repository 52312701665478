import { Vue, Component, Prop } from 'vue-property-decorator'
import router from '@/router'

// @ts-ignore
import BackIcon from 'vue-material-design-icons/ChevronLeft.vue'
import { Getter } from 'vuex-class'
import { GET_CHATS_LIST } from '@/store/root/getters'
import Chat from '@/core/models/Chat'

@Component({
  components: { BackIcon },
})

export default class Header extends Vue {
  @Prop({ required: true })
  public title: string;

  @Prop({ required: true })
  public image: string;

  @Prop({ required: true })
  public unreadChatsCount: number;

  @Getter(GET_CHATS_LIST)
  public chatsList: Chat[]

  public onTitleClicked (): void {
    this.goToChats()
  }

  public onBackClicked (): void {
    this.goToChats()
  }

  private goToChats (): void {
    router.push('/chats')
  }

  public mounted () {
  }
}
