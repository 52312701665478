import { GetterTree } from 'vuex'
import moment from 'moment'

import ApiRequest from '@/core/api/ApiRequest'
import AccessModel from '@/core/models/AccessModel'
import UserModel from '@/core/models/User'
import RootState from '@/store/root/state'
import Chat from '@/core/models/Chat'
import ChatMessage from '@/core/models/ChatMessage'
import LoggedUserModel from '@/core/models/LoggedUserModel'


export const HAS_UNREAD_MESSAGES = 'hasUnreadMessages'
export const IS_LOADING: string = 'isLoading'
export const IS_USER_DIALOG_SHOWN: string = 'isUserDialogShown'
export const GET_FATAL_ERROR_MESSAGE: string = 'getFatalErrorMessage'
export const GET_ACCESS_MODEL: string = 'getAccessModel'
export const GET_API_REQUEST: string = 'getApiRequest'
export const GET_CHATS_LIST_UPDATED_AT = 'getChatsListUpdatedAt'
export const GET_CHATS_LIST = 'getChatsList'
export const GET_LOGGED_USER = 'getLoggedUser'
export const GET_MESSAGES: string = 'messages'
export const GET_LAST_NOT_MY_MESSAGE: string = 'getLastNotMyMessage'
export const GET_ACTIVE_CHAT_ID: string = 'getActiveChatId'


export function isLoading (state: RootState): boolean {
  return state.isLoading
}

export function isUserDialogShown (state: RootState): boolean {
  return state.isUserDialogShown
}

export function getFatalErrorMessage (state: RootState): string | null {
  return state.fatalErrorMessage
}

export function getAccessModel (state: RootState): AccessModel | null {
  return state.accessModel
}

export function getApiRequest (state: RootState): ApiRequest {
  if (state.apiRequest === null) {
    throw new Error('ApiRequest not found')
  }
  return state.apiRequest
}

export function getChatsListUpdatedAt (state: RootState): string | null {
  return state.chatsListUpdatedAt
}

export function getChatsList (state: RootState): Chat[] {
  return state.chatsList
}

export function getActiveChatId (state: RootState): string {
  return state.activeChatId
}

export function getLoggedUser (state: RootState): LoggedUserModel | null {
  return state.loggedUser
}

export function getLastNotMyMessage (state: RootState) {
  const messages = state.messagesList
  const loggedUser = state.loggedUser
  const lastNotMyMessage = messages.findLast((message: any) => message.sender.id !== loggedUser?.id)

  return lastNotMyMessage
}

export function messages (state: RootState): ChatMessage[] {
  return state.messagesList
  // const messages: any[] = []
  // state.messagesList.forEach(message => {
  //   const newMessage = { ...message }
  //   newMessage.timestamp = moment(newMessage.timestamp)
  //   messages.push(newMessage)
  // })
  // return messages
}

export function hasUnreadMessages (state: RootState) {
  return state.hasUnreadMessages
}


export default {
  getActiveChatId,
  isLoading,
  getAccessModel,
  getApiRequest,
  getFatalErrorMessage,
  isUserDialogShown,
  getChatsListUpdatedAt,
  getChatsList,
  getLoggedUser,
  messages,
  hasUnreadMessages,
  getLastNotMyMessage,
} as GetterTree<RootState, any>
