import { MutationTree } from 'vuex'

import ApiRequest from '@/core/api/ApiRequest'
import AccessModel from '@/core/models/AccessModel'
import LoggedUserModel from '@/core/models/LoggedUserModel'
import Chat from '@/core/models/Chat'
import ChatMessage from '@/core/models/ChatMessage'
import RootState from '@/store/root/state'
import moment from 'moment'


export const SET_LOADING: string = 'setLoading'
export const SET_USER_DIALOG_SHOWN: string = 'setUserDialogShown'
export const SET_ACCESS_MODEL = 'setAccessModel'
export const SET_API_REQUEST = 'setApiRequest'

export const NEW_MESSAGE = 'newMessage'
export const SET_MESSAGES = 'setMessages'
export const ADD_MESSAGES = 'addMessages'
export const ADD_CHAT_MESSAGES = 'addChatMessages'
export const SET_UNREAD_MESSAGES = 'setUnreadMessages'
export const SET_FATAL_ERROR_MESSAGE = 'setFatalErrorMessage'
export const SET_CHATS_LIST = 'setChatsList'
export const SET_CHATS_LIST_UPDATED_AT = 'setChatsListUpdatedAt'
export const SET_ACITVE_CHAT_ID = 'setActiveChatId'

export const SET_LOGGED_USER = 'setLoggedUser'

export function setChatsList (state: RootState, chats: Chat[]) {
  state.chatsList = chats
}

export function setChatsListUpdatedAt (state: RootState, date: string) {
  state.chatsListUpdatedAt = moment(date).toISOString()
}

export function setLoading (state: RootState, isLoading: boolean) {
  state.isLoading = isLoading
}

export function setUserDialogShown (state: RootState, isUserDialogShown: boolean) {
  state.isUserDialogShown = isUserDialogShown
}

export function setFatalErrorMessage (state: RootState, fatalErrorMessage: string | null) {
  state.fatalErrorMessage = fatalErrorMessage
}

export function setAccessModel (state: RootState, model: AccessModel | null) {
  state.accessModel = model
}

export function setApiRequest (state: RootState, instance: ApiRequest) {
  state.apiRequest = instance
}

export function newMessage (state: RootState, message: ChatMessage) {
  state.messagesList = [...state.messagesList, message]
}

export function setMessages (state: RootState, messages: ChatMessage[]) {
  state.messagesList = messages
}

export function addChatMessages (state: RootState, payload: {chatId: string, messages: ChatMessage[]}) {
  const chatIndex = state.chatsList.findIndex((chat) => chat.id === payload.chatId)
  const existedIds = state.chatsList[chatIndex].messages.map((message: any) => message.id)

  for (const message of payload.messages) {
    if (existedIds.includes(message.id)) {
      continue
    }
    state.chatsList[chatIndex].messages.push(message)
  }

  if (payload.messages.length > 0) {
    const lastIndex = state.chatsList[chatIndex].messages.length - 1
    state.chatsList[chatIndex].lastMessage = state.chatsList[chatIndex].messages[lastIndex]
  }
}

export function addMessages (state: RootState, messages: ChatMessage[]) {
  state.messagesList = [...state.messagesList, ...messages]
}

export function setLoggedUser (state: RootState, model: LoggedUserModel | null) {
  state.loggedUser = model
}

export function setUnreadMessages (state: RootState, hasUnreadMessages: boolean) {
  state.hasUnreadMessages = hasUnreadMessages
}

export function setActiveChatId (state: RootState, chatId: string) {
  state.activeChatId = chatId
}

export default {
  setLoading,
  setFatalErrorMessage,
  setAccessModel,
  setApiRequest,
  setLoggedUser,
  setUserDialogShown,
  setChatsListUpdatedAt,
  setChatsList,
  setActiveChatId,

  newMessage,
  setMessages,
  addMessages,
  addChatMessages,
  setUnreadMessages,
} as MutationTree<RootState>
