import moment from 'moment'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import Dom from '@/utils/Dom'
import ChatMessage from '@/core/models/ChatMessage'

// @ts-ignore
import linkParse from '@/mixins/linkParse'
import { GET_MESSAGES } from '@/store/root/getters'

// @ts-ignore
import CheckIcon from 'vue-material-design-icons/Check'
// @ts-ignore
import CheckAll from 'vue-material-design-icons/CheckAll'
import { Getter } from 'vuex-class'

@Component({
  components: { CheckIcon, CheckAll },
})

export default class Message extends Mixins(linkParse) {
  public STATUS_NEW: ChatMessage['status'] = 'new';
  public STATUS_SENDED: ChatMessage['status'] = 'sent';
  public STATUS_VIEWED: ChatMessage['status'] = 'viewed';

  public showConfirmation = false

  @Prop({ required: true })
  public message: any;

  @Getter(GET_MESSAGES)
  public messages: any[]

  public onImageClicked (message: any): void {
    this.$emit('onImageClicked', message)
  }

  public onPreviewLoaded () {
    Dom.scrollToBottom()
  }

  public toFormat (message: any): string {
    return moment(this.message.createdAt).format('HH:mm')
  }
}
